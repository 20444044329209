import React from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Bio from '../components/Bio'
import Layout from '../components/Layout'
import { rhythm } from '../utils/typography'

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const siteDescription = data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[{ name: 'description', content: siteDescription }]}
          title={siteTitle}
        />
        <Bio />

        <h4>Work History</h4>

        <ul>
          <li>
            <a href="https://boardgamelab.app/">Boardgame Lab</a> (
            <i>2020 - present</i>).
          </li>
        </ul>

        <ul>
          <li>
            Google (<i>2011 - 2020</i>).
          </li>
        </ul>

        <h4>Hobby Projects</h4>

        <ul>
          <li>
            <a href="https://boardgame.io/">boardgame.io</a>: Open Source Game
            Engine for Turn-Based Games.
          </li>
        </ul>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
